import React, { useEffect } from "react";
import "./style/darkMode.scss";

const DarkMode = () => {
  let clickedClass = "clicked";
  const body = document.body;
  //const pet = document.querySelector("#pet__container");
  const lightTheme = "light";
  const darkTheme = "dark";
  const walk = "walk";
  const sleep = "sleep";
  const stay = "stay";
  const backward = "backward";
  let theme;
  //   const ref = useRef(null);
  const moves = {
    0: "walk",
    1: "stay",
    2: "sleep",
    3: "backward",
  };

  const petMoves = (e) => {
    //console.log("coco jambo");
    const pet = document.getElementById("pet__container");
    const body = document.body;
    const root = document.querySelector(":root");

    function getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);

      let rect = pet.getBoundingClientRect();
      let bodyRect = body.scrollWidth;
      console.log("body:", bodyRect);

      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    if (body.classList.contains("light")) {
      console.log("light");
      switch (getRandomIntInclusive(0, 2)) {
        case 0: {
          //walk
          let rect = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          if (rect.left <= bodyRect - 100) {
            root.style.setProperty("--petPosition", rect.left + "px");
          } else {
            root.style.setProperty("--petPosition", 0 + "px");
          }
          console.log(moves[0]);
          pet.classList.remove(sleep);
          pet.classList.remove(stay);
          pet.classList.remove(backward);
          pet.classList.add(walk);

          break;
        }
        case 1: {
          //stay
          let rect = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          root.style.setProperty("--petPosition", rect.left + "px");
          console.log(moves[1]);
          pet.classList.remove(sleep);
          pet.classList.add(stay);
          pet.classList.remove(backward);
          pet.classList.remove(walk);
          break;
        }
        case 2: {
          //sleep
          let rect = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          root.style.setProperty("--petPosition", rect.left + "px");
          root.style.setProperty("--variable", "lightblue");
          console.log(moves[2]);
          // pet.classList.replace(walk, sleep);
          pet.classList.add(sleep);
          pet.classList.remove(stay);
          pet.classList.remove(backward);
          pet.classList.remove(walk);
          break;
        }
        case 3: {
          //backward
          let recta = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          root.style.setProperty("--petPosition", recta.left + "px");
          // root.style.setProperty("--bodySize", bodyRect.left + "px");
          console.log(moves[3]);
          pet.classList.remove(sleep);
          pet.classList.remove(stay);
          pet.classList.add(backward);
          // pet.classList.add(walk);
          pet.classList.remove(walk);
          break;
        }
        default: {
          //default
          console.log("default");
          break;
        }
      }
    } else {
      console.log("dark");
      switch (getRandomIntInclusive(2, 2)) {
        case 0: {
          //walk
          let rect = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          if (rect.left <= bodyRect - 100) {
            root.style.setProperty("--petPosition", rect.left + "px");
          } else {
            root.style.setProperty("--petPosition", 0 + "px");
          }
          console.log(moves[0]);
          pet.classList.remove(sleep);
          pet.classList.remove(stay);
          pet.classList.remove(backward);
          pet.classList.add(walk);

          break;
        }
        case 1: {
          //stay
          let rect = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          root.style.setProperty("--petPosition", rect.left + "px");
          console.log(moves[1]);
          pet.classList.remove(sleep);
          pet.classList.add(stay);
          pet.classList.remove(backward);
          pet.classList.remove(walk);
          break;
        }
        case 2: {
          //sleep
          let rect = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          root.style.setProperty("--petPosition", rect.left + "px");
          root.style.setProperty("--variable", "lightblue");
          console.log(moves[2]);
          // pet.classList.replace(walk, sleep);
          pet.classList.add(sleep);
          pet.classList.remove(stay);
          pet.classList.remove(backward);
          pet.classList.remove(walk);
          break;
        }
        case 3: {
          //backward
          let recta = pet.getBoundingClientRect();
          let bodyRect = body.scrollWidth;
          console.log("body:", bodyRect);
          root.style.setProperty("--petPosition", recta.left + "px");
          // root.style.setProperty("--bodySize", bodyRect.left + "px");
          console.log(moves[3]);
          pet.classList.remove(sleep);
          pet.classList.remove(stay);
          pet.classList.add(backward);
          // pet.classList.add(walk);
          pet.classList.remove(walk);
          break;
        }
        default: {
          //default
          console.log("default");
          break;
        }
      }
    }
  };

  setInterval(petMoves, 5000);

  useEffect(() => {
    //another dom accessing method
    const pet = document.getElementById("pet__container");
    console.log(pet, "2");
  }, []);

  if (localStorage) {
    theme = localStorage.getItem("theme");
  }

  if (theme === lightTheme || theme === darkTheme) {
    //const pet = document.getElementById("pet__container");
    body.classList.add(theme);
    //pet.classList.add(walk);
    //console.log(pet, "3");
  } else {
    body.classList.add(lightTheme);
    //const pet = document.getElementById("pet__container");

    //pet.classList.add(walk);
    //console.log(pet, "4");
  }
  const switchTheme = (e) => {
    const pet = document.getElementById("pet__container");

    if (theme === darkTheme) {
      body.classList.replace(darkTheme, lightTheme);
      pet.classList.replace(sleep, walk);
      e.target.classList.remove(clickedClass);
      localStorage.setItem("theme", "light");
      console.log(pet, "5");
      theme = lightTheme;
    } else {
      body.classList.replace(lightTheme, darkTheme);
      pet.classList.replace(walk, sleep);
      e.target.classList.add(clickedClass);
      localStorage.setItem("theme", "dark ");
      console.log(pet, "6");
      theme = darkTheme;
    }
  };

  return (
    <>
      <button
        className={theme === "dark" ? clickedClass : ""}
        id="darkMode"
        onClick={(e) => switchTheme(e)}
      ></button>
    </>
  );
};

export default DarkMode;
