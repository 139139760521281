import React, { useRef, useEffect } from "react";
import DarkMode from "./DarkMode";
import "./style/main.scss";
import useSound from "use-sound";
import mySound from "./img/mishaBarking.wav";

function App() {
  const ref = useRef(null);

  useEffect(() => {
    // 👇️ use a ref (best)
    //const el2 = ref.current;
    //console.log(el2);
    //another dom accessing method
    //const el = document.getElementById("pet__container");
    //console.log(el, "2");
  }, []);

  const [playSound] = useSound(mySound); //when clicked the dog play barking sound
  const petClicked = (e) => {
    const pet = document.getElementById("dog");
    console.log("pet clicked");
    playSound();
  };

  return (
    <div className="App" id="app">
      <header className="header">
        <DarkMode />
      </header>

      <article>
        <h1>Welcome to pet project</h1>
      </article>

      <footer className="pet__footer">
        <div ref={ref} className="dog__container walk" id="pet__container">
          <div id="dog" className="dog" onClick={() => petClicked()}></div>
        </div>
      </footer>
    </div>
  );
}

export default App;
